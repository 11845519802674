import { RestService, SSEService, SSEStructType } from '@superbet-group/offer.clients.lib'
import type { 
    SSEStructDTO, 
    StructDto, EventDto, 
    SSEEventDTO, TournamentDto, 
    CategoryDto, 
    SportDto } from '@superbet-group/offer.clients.lib'
import ms from 'ms';
import StructManager from './StructManager';
import Match from '@src/models/Match';
import { EventMapper } from '@src/mappers/EventMapper';
import { getOfferLocaleLangForEnv } from '@src/helpers/helpers';
import { bufferTime, filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import type { SSEEventMessage, SSEStructMessage } from '@src/models/SSEMessage'
import type { Sport, Category, Tournament } from '@src/models/struct/Struct';
import { CategoryMapper, SportMapper, TournamentMapper } from '@src/mappers/StructMappers';
import ConfigService from './config/ConfigService';

export default class OfferService {
    private static instance: OfferService
    private readonly restService: RestService
    private readonly eventMapper = new EventMapper()
    private readonly sseService: SSEService
    private readonly sportMapper = new SportMapper()
    private readonly categoryMapper = new CategoryMapper()
    private readonly tournamentMapper = new TournamentMapper()
    private readonly errorLogging: boolean = false


    public static getInstance(): OfferService {
        if (!OfferService.instance) {
            OfferService.instance = new OfferService()
        }
        
        return OfferService.instance
    }

    constructor() {
        const retryCount = ConfigService.getConfig().flags.enableRequestRetries ? 3 : 0
        this.errorLogging = ConfigService.getConfig().flags.enableOfferClientErrorLogging
        this.restService = new RestService(import.meta.env.VITE_APP_OFFER_HOST_URL, getOfferLocaleLangForEnv(), ms('30s'), retryCount)
        this.sseService = new SSEService(import.meta.env.VITE_APP_OFFER_HOST_URL, getOfferLocaleLangForEnv())
    }

    public getStruct(): Promise<StructDto> {
        try {
            return this.restService.getStruct();
        } catch (e) {
            if(this.errorLogging){
                console.error('Get offer struct:', e)
            }
            throw e
        }
    }

    public async getLiveEvents(startDate: Date): Promise<Match[]> {
        try {
            const events = await this.restService.getLiveEvents(startDate);

            const matches = this.eventMapper.map(events, true)

            StructManager.getInstance().denormalizeEvents(matches)

            return matches
        } catch (e) {
            if(this.errorLogging){
                console.error('Get offer by date (live):', e)
            }
           
            throw e
        }
    }

    public async getEventsById(eventIds: number[]): Promise<Match[]> {
        try {
            const eventPromises = eventIds.map((eventId) => this.restService.getEventById(eventId))
            const events = await Promise.all(eventPromises)

            const matches = events.map((e: EventDto) =>
                this.eventMapper.createTargetObject(e)
            )

            StructManager.getInstance().denormalizeEvents(matches)

            return matches
        } catch (e) {
            if(this.errorLogging){
                console.error('Get event by Id:', e)
            }
            
            throw e
        }
    }

    public subscribeToAllLiveEventChanges(
        callback: (events: SSEEventMessage[]) => void
    ): Subscription {
        return this.sseService.createAllLiveEventChangeObservables().pipe(
            bufferTime(1000),
            filter((e: SSEEventDTO[]) => e.length > 0),
            map((e: SSEEventDTO[]) => e.filter((e) => Boolean(e.resourceId) && Boolean(e.timestamp))),
            map((e: SSEEventDTO[]) => {
                return e.map((e) => {
                    let event: Match | null = null;

                    if (e.data) {
                        event = this.eventMapper.map(e.data);
                        StructManager.getInstance().denormalizeEvent(event);
                    }
            
                    return {
                        event: event,
                        resourceId: e.resourceId!.split(':')[1],
                        timestamp: e.timestamp
                    };
                });
            })

        ).subscribe(callback as any);
    }

    public subscribeToEventChanges(
        eventId: number,
        callback: ({ resourceId, timestamp, event }: SSEEventMessage) => void
    ): Subscription {
        return this.sseService.createEventChangesObservable(eventId).pipe(
            bufferTime(1000),
            filter((e: SSEEventDTO[]) => e.length > 0),
            map((e: SSEEventDTO[]) => e[e.length - 1]), //Take only last event since we don't need all of them
            filter((e: SSEEventDTO) => Boolean(e.resourceId) && Boolean(e.timestamp)),
            map((e: SSEEventDTO) => {
                let event: Match | null = null;

                if (e.data) {
                    event = this.eventMapper.map(e.data);
                    StructManager.getInstance().denormalizeEvent(event);
                }
        
                return {
                    event: event,
                    resourceId: e.resourceId!.split(':')[1],
                    timestamp: e.timestamp
                };
            })
        ).subscribe(callback as any)
    }

    public subscribeToStructChanges(callback: (e: SSEStructMessage[]) => void): Subscription {
        return this.sseService.createStructChangesObservable().pipe(
            bufferTime(2500),
            filter((e: SSEStructDTO[]) => e.length > 0),
            map((e: SSEStructDTO[]) => e.filter((e) => Boolean(e.resourceId) && Boolean(e.timestamp))),
            map((e: SSEStructDTO[]) => {
                return e.map((e) => {
                    let structData: Sport | Category | Tournament | null = null;
                    if(e.data){
                        switch(e.type){
                            case SSEStructType.Sport:{
                                structData = this.sportMapper.map(e.data as SportDto)
                                break;
                            }
                            case SSEStructType.Category:{
                                structData = this.categoryMapper.map(e.data as CategoryDto)
                                break;
                            }
                            case SSEStructType.Tournament:{
                                structData = this.tournamentMapper.map(e.data as TournamentDto)
                                break;
                            }
                        }
                    }
                    return {
                        timestamp: e.timestamp,
                        resourceId: e.resourceId,
                        data: structData,
                        type: e.type,
                        id: e.id
                    }
                })
            })          
        ).subscribe(callback as any);
    }
}
