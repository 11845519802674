<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { storeToRefs } from 'pinia'
import ms from 'ms'
import { useLiveOfferStore } from '@src/stores/offer'
import OfferTableHeader from './components/OfferTableHeader.vue'
import OfferTableBody from './components/OfferTableBody.vue'
import { initializeNetworkAnalytics } from '@src/network/NetworkAnalytics';
import FlagsService from './services/flags/FlagsService'
import { FeatureFlags } from './services/flags/Flags'
import { APP_LANG } from '@src/constants/constants'
import ConfigService from './services/config/ConfigService'

const date = ref(Date.now());

const store = useLiveOfferStore()
const { eventsBySport, isLiveOfferFetched } = storeToRefs(store)

onBeforeMount(async () => {
    await ConfigService.initializeConfig()
    ConfigService.subscribeToConfigChanges(() => {
        window.location.reload()
    })
    await initializeNetworkAnalytics();

    await store.fetchStruct()
    if(ConfigService.getConfig().flags.enableStructSubscription){
        store.subscribeToStructChanges()
    } else {
        setInterval(() => {
            store.fetchStruct();
        }, ms('15m'));
    }

    store.subscribeToLiveEventChanges()
    await store.fetchLiveEvents()
})
setTimeout(() => {
    window.location.reload()
}, ms('1h'))

setInterval(() => {
    date.value = Date.now();
}, 1000)
</script>

<template>
    <div v-if="isLiveOfferFetched" class="offer">
        <i class="offer__date-time">
            <span>{{ $d(date, 'weekday', APP_LANG) }}</span>
            <span class="date">{{ $d(date, 'date', APP_LANG) }}</span>
            <span>{{ $d(date, 'time', APP_LANG) }}</span>
        </i>
        <div v-for="sport in eventsBySport" :key="sport.uniqueId" class="offer__table">
            <OfferTableHeader :sport="sport" />
            <OfferTableBody :sport="sport" />
        </div>
        <div v-if="!eventsBySport.length" class="offer__no-events">
            <i18n-t keypath="No Events" tag="div" scope="global">
                <template #live>
                    <span class="live-block">{{ $t('Live') }}</span>
                </template>
            </i18n-t>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.offer {
    width: 100%;
    padding: 0 $space-lg;

    &__date-time {
        display: inline-block;
        margin-top: $space-l;
        margin-bottom: -$space-base;
        background-color: $bg-inverted-color;
        border-radius: $space-l;
        padding: $space-sm $space-l;
        text-transform: uppercase;
        font-size: $font-size-lg;
        font-weight: $font-weight-boldest;

        .date {
            padding: 0 $space-s 0 $space-l;
            font-weight: $font-weight-light;
        }
    }

    &__table {
        margin-top: $space-lg;
    }

    &__no-events {
        height: calc(100vh - ($space-lg * 2));
        margin: $space-lg 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $bg-inverted-color;
        border-radius: $space-base;
        text-transform: uppercase;
        font-size: $font-size-xxl;
        font-weight: $font-weight-boldest;

        .live-block {
            background-color: $white;
            color: $brand-red;
            border-radius: $table-border-radius;
            padding: $space-s $space-base;

            &::before {
                content: "";
                display: inline-block;
                width: $space-base;
                height: $space-base;
                background: $brand-red;
                border-radius: 50%;
                margin: $space-xxs $space-xxs $space-xxs 0;
            }
        }
    }
}
</style>
