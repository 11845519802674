export enum FeatureFlags {
    networkAnalytics = 'monitoring.network-metrics',
    structSubscription = 'retail.offer-display.live.struct-subscription',
    networkRetries = 'retail.offer-display.live.network-retries',
    offerClientErrorLogging = 'retail.offer-display.live.offer-client-error-logging',

}

export const FeatureFlagsDefaultValues = {
    [FeatureFlags.networkAnalytics]: false,
    [FeatureFlags.structSubscription]: true,
    [FeatureFlags.networkRetries]: true,
    [FeatureFlags.offerClientErrorLogging]: true,
};
