import { FeatureFlags } from "../flags/Flags"
import FlagsService from "../flags/FlagsService"
import { type Config } from "./Config"

export default class ConfigService {
    private static config: Config | null = null

    public static async initializeConfig() {
        const enableStructSubscription = await FlagsService.getInstance().getFeatureFlag<boolean>(FeatureFlags.structSubscription)
        const enableRequestRetries = await FlagsService.getInstance().getFeatureFlag<boolean>(FeatureFlags.networkRetries)
        const enableOfferClientErrorLogging = await FlagsService.getInstance().getFeatureFlag<boolean>(FeatureFlags.offerClientErrorLogging)

        ConfigService.config = {
            flags: {
                enableStructSubscription,
                enableRequestRetries,
                enableOfferClientErrorLogging
            }
        }
    }

    public static getConfig(): Config {
        if (!ConfigService.config) {
            const message = 'Cannot get config object'

            throw new Error(message)
        }

        return ConfigService.config
    }

    public static subscribeToConfigChanges(callback) {
        const flags = [
            FeatureFlags.structSubscription,
            FeatureFlags.networkRetries,
            FeatureFlags.offerClientErrorLogging
        ]

        flags.forEach(flag => {
            FlagsService.getInstance().subscribeToFeatureFlagChange(flag, callback, { triggerOnlyOnChange: true })
        })
    }
}
